<template>
    <section class="content">
    <div class="flex justify-content-center">
    <div class="overlay-wrapper">
        <div class="w-100 card">
        <div class="overlay" v-if="loadingContent"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
        <div class="card-body">
            <div class="row">
            <div class="col-12 d-flex justify-content-end mb-2">
                <div class="row form-horizontal justify-content-end">
                    <div class="col-auto col-xs-4">
                    <h4>Periode Laporan</h4>
                    </div>
                    <div class="col-auto col-xs-4">
                    <div class="input-group mb-3">
                        <input type="text" ref="daterange" class="form-control" style="border-right: 0"/>
                        <div class="input-group-append">
                        <div
                            class="input-group-text"
                            style="background-color: #fff">
                            <span><span class="fa fa-calendar"></span></span>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-auto col-xs-4"></div>
                    <div class="col-auto col-xs-4">
                        <div class="btn-group" ref="toolbar">
                        <button type="button" data-action="read" class="btn btn-default"><i
                                class="fa fa-sync-alt"></i></button>
                        <button type="button" data-action="export-xlsx" class="btn btn-default" :disabled="loading" v-on:click="downloadXlsx"><i class="fa fa-download"></i> <span class="d-none d-md-inline-block">Download</span></button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="mb-2"></div>
            <div class="table-responsive">
                <table style="width:100%" class="table table-hover" ref="tblppnkeluaran">
                <thead>
                    <tr>
                    <th>TGL JURNAL</th>
                    <th>SHIPPER ID</th>
                    <th>NAMA</th>
                    <th>NO. JURNAL</th>
                    <th>REFERENSI</th>
                    <th>TGL TERAKHIR</th>
                    <th>STATUS KIRIMAN</th>
                    <th>PPN COD FEE</th>
                    <th>NILAI</th>
                    <th>PPN</th>
                    <th>TOTAL</th>
                    <th>KETERANGAN</th>
                    </tr>
                </thead>
                <tbody @click="handleClick"></tbody>
                <tfoot>
                <tr>
                <th colspan="8"></th>
                <th></th>
                <th colspan="2"></th>
                </tr>
                </tfoot>
                </table>
            </div>
        </div>
        </div>
    </div>
    </div>
    </section>
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import moment from "moment";
import "daterangepicker/daterangepicker.css";

export default {
    name: "Ppnkeluaran",
    data() {
        return {
            dt1: "",
            dt2: "",
            dateStart: "",
            dateEnd: "",
            errors: [],
            method: "",
            jurnal: {
                coa_id: '',
                coa_no: '',
                coa_name: '',
            },
            filter: {},
            roles: "",
            role_id: "",
            coa_id: "",
            loadingContent: false,
            loading: false,
        };
    },
    created: function () {
        this.roles = this.$route.meta.roles;
        console.log("load initial data", this.$route);
        this.dateStart = moment().add(-1, 'M').startOf("month").format("YYYY-MM-DD");
        this.dateEnd = moment().format("YYYY-MM-DD");

        //set filter
        this.filter.dt1 = this.dateStart;
        this.filter.dt2 = this.dateEnd;
    },
    methods: {
        onPeriode() {
        this.tbl.api().ajax.reload();
        },
        downloadXlsx: function (e) {
            const self = this;
            self.loading = true;
            self.loadingContent = true;
            var filter = JSON.parse(JSON.stringify(self.filter));
                filter.q = $("input[name=q]").val();
                filter.category = $("select[name=category]").val();

            var data = Object.keys(filter)
            .map((key) =>
                encodeURIComponent(key) + "=" + encodeURIComponent(filter[key])
            ).join("&");

            authFetch("/report/ppn_keluaran/excell", {
                method: "POST",
                body: data,
            })
            .then((response) => {
                if (response.status === 201) {
                } else if (response.status === 400) {
                } else if (response.status === 500){
                    Swal.fire("Timeout!", ``, "error");
                    this.loading = false;
                    this.loadingContent = false;
                    return false;
                }

                return response.blob();
            })
            .then((blob) => {
            setTimeout(() => {
                self.loading = false;
            }, 1000);
            self.loadingContent = false;
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = "data.xlsx";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
            });
        },
        handleClick(e) {
            const er = this.$refs;
            if (e.target.matches(".link-role")) {
            }
        },
    },
    mounted() {
    const e = this.$refs;
    const self = this;
    new Daterangepicker(this.$refs.daterange, {
        startDate: moment().add(-1, 'M').startOf("month"),
        endDate: moment(),
        locale: {
            format: 'DD/MM/YYYY'
        }
        }, function (d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.filter.dt1 = self.dateStart;
        self.filter.dt2 = self.dateEnd;
        self.onPeriode();
        });
    
    this.tbl = createTable(e.tblppnkeluaran, {
        title: "",
        roles: this.$route.params.roles,
        toolbarEl: e.toolbar,
        selectedRowClass: "",
        serverSide: true,
        frame: false,
        processing: true,
        sort: false,
        language: {
            processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
        lengthMenu: [[10, 25, 50, 100, 1000, -1], [ 10, 25, 50, 100, 1000, 'Semua' ]],
        displayLength: 10,
        lengthChange: true,
        ajax: "/report/ppn_keluaran",
        paramData: function (d) {
            var filter = JSON.parse(JSON.stringify(self.filter));
			for (const key in filter) {
				d[key] = filter[key] === false ? 0: filter[key];
			}
        },
        columns: [
            { data: "journal_date" },
            { data: "shipper_code" },
            { data: "nama" },
            { data: "journal_no" },
            { data: "referensi" },
            { data: "updated_on" },
            { data: "status",
            render: function (data, type, row, meta) {
                if (row.cancel == 1)
                    return '<span class="badge badge-danger">Cancel</span>';
                else if (row.pickupStatus == 0)
                    return '<span class="badge badge-inverse">DRAFT</span>';
                else {
                    if (row.trackingStatus == 0)
                        if(row.order_type == 1){
                            return '<span class="badge badge-secondary">Menunggu Drop</span>';
                        } else {
                            return '<span class="badge badge-secondary">Menunggu Pickup</span>';
                        }
                    else if (row.trackingStatus == 1)
                        return '<span class="badge badge-warning">On Proses</span>';
                    else if (row.trackingStatus == 2)
                        return '<span class="badge badge-success">Terkirim</span>';
                    else if (row.trackingStatus == 3)
                        return '<span class="badge badge-warning">Proses Retur</span>';
                    else if (row.trackingStatus == 4) {
                        return '<span class="badge badge-info">Retur</span>';
                    } else if (row.trackingStatus == 5)
                        return '<span class="badge badge-danger">Bermasalah</span>';
                }
            },
            },
            { 
            data: "ppncod_admin", 
            class:'text-right',
            width: "90px",
            render: function(data, type, row, meta) {
                return "Rp " + formatCurrency(data);
            }
            },
            { 
            data: "feecod_shipper", 
            class:'text-right',
            width: "90px",
            render: function(data, type, row, meta) {
                return "Rp " + formatCurrency(data);
            }
            },
            { 
            data: "ppncod_shipper", 
            class:'text-right',
            width: "90px",
            render: function(data, type, row, meta) {
                return "Rp " + formatCurrency(data);
            }
            },
            { 
            data: "total", 
            class:'text-right',
            width: "90px",
            render: function(data, type, row, meta) {
                return "Rp " + formatCurrency(data);
            }
            },
            {
            data: "keterangan",
            render: function (data, type, row, meta) {
                if (row.cancel == 1)
                    return '<span class="badge badge-danger">Paket sudah dicancel</span>';
                else if (row.pickupStatus == 0)
                    return '<span class="badge badge-inverse">DRAFT</span>';
                else {
                    if (row.trackingStatus == 0)
                        return '<span class="badge badge-secondary">Paket menunggu pickup</span>';
                    else if (row.trackingStatus == 1)
                        return '<span class="badge badge-warning">Paket on proses</span>';
                    else if (row.trackingStatus == 2)
                        if (row.status_cod > 0)
                        return '<span class="badge badge-success">Pengiriman COD</span>';
                        else
                        return '<span class="badge badge-success">Pengiriman Non COD</span>';
                    else if (row.trackingStatus == 3)
                        return '<span class="badge badge-warning">Paket proses retur</span>';
                    else if (row.trackingStatus == 4) {
                        if (row.retStatus == 1) return '<span class="badge badge-success">Paket retur diterima</span>';
                        else if (!row.retStatus && !row.retClaim)
                            return '<span class="badge badge-info">Paket retur diserahkan</span>';
                        else if (row.retClaim == 1)
                            return '<span class="badge badge-danger">Paket retur diclaim</span>';
                        else if (row.retClaim == 2)
                            return '<span class="badge badge-success">Paket retur disetujui</span>';
                        else if (row.retClaim == 3)
                            return '<span class="badge badge-danger">Paket retur ditolak</span>';
                    } else if (row.trackingStatus == 5)
                        return '<span class="badge badge-danger">Paket bermasalah</span>';
                }
            },
        },
        ],
        filterBy: [4],
        rowCallback: function (row, data) {},
        initComplete: function () {
            $('.loading-overlay').removeClass('show');
        },
        footerCallback:function ( row, data, start, end, display ){
        var api = this.api(), data;
            // Total over this page
            var ppn;
            ppn = 
                api
                .column(8, { page: 'current'})
                .data()
                .reduce( function (a, b) {
                    var x = parseFloat( a ) || 0;
                    var y = parseFloat( b ) || 0;
                    return x + y;
                }, 0);

            // Update footer
                $( api.column(8).footer() ).html(
                    'Rp '+ formatCurrency(ppn)
                );
        }
        });
    },
}
</script>